@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins&family=Inter&family=Lato&display=swap");

@font-face {
  font-family: BeVietnam;
  src: url("fonts/BeVietnam-Regular.ttf") format("truetype");
}

@font-face {
  font-family: Thunder;
  src: url("fonts/Thunder-MediumLC.otf") format("opentype");
}

@font-face {
  font-family: ThunderBold;
  src: url("fonts/Thunder-BoldLC.otf") format("opentype");
}


body {
    @apply bg-[#111];
    scroll-behavior: smooth;
}

html.dark {
    body {
        @apply bg-[#111111];
    }
}

h1 {
  font-size: 96px;
  line-height: 96px;
  color: white;
  font-family: Thunder;
}

h2 {
  font-size: 56px;
  line-height: 80px;
  color: white;
  font-family: Thunder;
}

h3 {
  font-family: Thunder;
  font-size: 40px;
  line-height: 56px;
  color: white;
  font-family: Thunder;
}

h6 {
  @apply text-primary font-[BeVietnam] uppercase text-[16px] font-light leading-[28px];
}

p {
  @apply text-[16px] leading-[28px] text-[#111111] font-[BeVietnam];
}

.section {
  max-width: 1300px;
  width: 100%;
}

.section-title {
  animation: slideLeft 0.3s linear;
}

@keyframes slideLeft {
  0% {
    transform: translateY(70px);
    opacity: 0;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}

.opacity-animation {
  animation: opacityAnimation 1s linear;
}

@keyframes opacityAnimation {
  0% {
    opacity: 0;
  }
  
  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.opacity-animation-long {
  animation: opacityAnimationLong 1.5s linear;
}

@keyframes opacityAnimationLong {
  0% {
    opacity: 0;
    transform: translateY(-100px)
  }
  
  70% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translateY(0px)
  }
}

.opacity-animation-long-with-delay {
  animation: opacityAnimationLong 1.5s linear;
}

.opacity-animation-long-with-delay-middle {
  animation: opacityAnimationLongMiddle 1.75s linear;
}

@keyframes opacityAnimationLongMiddle {
  0% {
    opacity: 0;
    transform: translateY(-150px)
  }
  
  70% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translateY(-50px)
  }
}

.filter:hover {
  filter: brightness(0) saturate(100%) invert(87%) sepia(26%) saturate(2478%) hue-rotate(351deg) brightness(101%) contrast(100%);
}

@media screen and (max-width: 1024px) {
  .benefit-cards-margin {
    margin-left: 50px;
  }
}

@media screen and (max-width: 1024px) {
  h1 {
    font-size: 56px;
    line-height: 80px;
  }

}

@media screen and (max-width: 768px) {
  .section-title {
    margin-left: 16px;
  }

  .benefit-cards-margin {
    margin-left: 0px;
    flex-direction: column;
  }
  
  .fixed-container-margin {
    margin-left: calc(100vw - 50px);
    z-index: 40 !important
  }
}

@layer utilities {
  @variants responsive {
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .no-scrollbar {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
  }
}

.flip-card {
  background-color: transparent;
  width: fit-content;
  height: 600px;
}

.flip-card-inner {
  position: relative;
  text-align: center;
  transition: transform 1s;
  transform-style: preserve-3d;
}

.flip-card-front {
  position: absolute;
  width: 390px;
  height: 600px;
  -webkit-backface-visibility: hidden !important;
  backface-visibility: hidden !important;
  -moz-backface-visibility: hidden;
  transform: rotateX(0deg)
}

.flip-card-back {
  position: absolute;
  width: fit-content;
  height: 600px;
  -webkit-backface-visibility: hidden !important;
  backface-visibility: hidden !important;
  -moz-backface-visibility: hidden;
  transform: rotateX(0deg)
}
.flip-card-back {
  transform: rotateY(180deg);
}

.increase-dimensions {
  animation: increase 0.1s linear;
  width: 390px;
} 

@keyframes increase {
    0% {
      width: 178px;
    }

    100% {
      width: 390px
    }
}

.lower-dimension {
  animation: decrease 0.1s linear;
  width: 178px;
} 

@keyframes decrease {
  0% {
    width: 390px
  }

  100% {
    width: 178px;
  }
}

.language-button {
  @apply font-[BeVietnam] text-[10px] text-white hover:text-primary p-2;
  font-weight: 300;
}

.float-right {
  animation: floatRight 1s infinite;
}

@keyframes floatRight {
  0% {
    transform: translateX(0px)
  }

  50% {
    transform: translateX(10px)
  }

  100% {
    transform: translateX(0px)
  }
}

.float-left {
  animation: floatLeft 1s infinite;
}

@keyframes floatLeft {
  0% {
    transform: translateX(0px)
  }

  50% {
    transform: translateX(-10px)
  }

  100% {
    transform: translateX(0px)
  }
}

@media screen and (max-width: 1024px) {
  h2 {
    font-size: 38px;
    line-height: 56px;
  }
}

.translate-to-top {
  animation: toTop 0.5s ease-in forwards;
  transform: translateY(0px);
}

@keyframes toTop {
  0% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0px);
  }
}

.translate-to-top {
  animation: toTop 0.5s ease-in forwards;
  transform: translateY(0px);
}

@keyframes toTop {
  0% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0px);
  }
}

.translate-to-bottom {
  animation: toBottom 0.5s ease-in forwards;
  transform: translateY(0px);
}

@keyframes toBottom {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}

input:focus {
  --tw-ring-color: transparent !important;
}